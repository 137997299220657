<template>
  <div>
    <div class="setup-container" v-if="!isStarted">
      <Loading v-if="categories.length == 0" text="Loading..." />
      <div v-else>
        <HeaderLogo />

        <p>Welcome to US Vowels. We will help you master the vowels of American English.</p>
        <p>These exercises emphasize the crucial role that vowels play in distinguishing words, especially for non-native speakers who may find similar sounds confusing.</p>

        <CategorySelector sectionName="Audio Matching" 
          :source="categories" 
          type="audio-matching"
          :chosenOption="chosenOption"
          :maxQuestionLimit="5"
          :maxChoiceLimit="5" 
          @category-selected="setOption" />

        <CategorySelector sectionName="🆕 Vowel Guessing 🆕" 
          :source="categories"
          type="vowel-matching"
          :chosenOption="chosenOption"
          :maxQuestionLimit="5"
          @category-selected="setOption" />

        <CategorySelector sectionName="Word to IPA" 
          :source="categories"
          type="ipa-matching"
          direction="word-to-ipa"
          :chosenOption="chosenOption"
          :maxQuestionLimit="5" 
          :maxChoiceLimit="5"
          @category-selected="setOption" />

        <CategorySelector sectionName="IPA to Word" 
          :source="categories"
          type="ipa-matching"
          direction="ipa-to-word"
          :chosenOption="chosenOption"
          @category-selected="setOption" />

      </div>
    </div>
    <div v-if="isStarted">
      <QuizContent
          :questions="questions"
          v-on:update-questions="updateQuestions"
          v-on:update-is-started="updateIsStarted"
          v-on:update-chosen-option="setOption"
          v-on:start-quiz="startQuiz"
          v-on:reset-quiz="resetQuiz"
          v-on:reset-all="resetAll"
        />
    </div>
  </div>
</template>

<script>
import Loading from './LoadingMain.vue';
import CategorySelector from './CategorySelector.vue';
import QuizContent from './QuizContent.vue';
import HeaderLogo from './HeaderLogo.vue';
import fileCategories from '../assets/categories.json';

export default {
  name: 'Quiz-Main',
  components: {
    Loading,
    CategorySelector,
    QuizContent,
    HeaderLogo
  },
  data() {
    return {
      isStarted: false,
      categories: [],
      chosenOption: null,
      questions: [],
      originalQuestions: [],
      vowels: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // Load categories from JSON resources
      this.categories = fileCategories.categories;
      this.vowels = [
        // Diphthongs
        'eɪ', 'aɪ', 'oʊ', 'aʊ', 'ɔɪ',
        
        // Monophthongs
        'iː', 'ɪ', 'ɛ', 'æ', 'ɑː', 'ɔː', 'ʊ', 'uː', 'ʌ', 'ə', 
        
        // Rhotic Vowels
        'ɚ' //'ɝː', 
      ]
    },
    
    // Update the chosen category
    setOption(chosenOption) {
      this.chosenOption = chosenOption; 

      // Automatically start quiz on click
      this.startQuiz();
    },

    // Fetch question data and call populateQuestions()
    startQuiz() {
      this.isStarted = false;
      this.questions = [];
      this.originalQuestions = [];
      this.$nextTick(() => {
        this.isStarted = true;
        this.populateQuestions(this.chosenOption);
      });
    },

    // Reset the quiz state
    resetQuiz() {
      // Reset questions to the original state
      this.questions = JSON.parse(JSON.stringify(this.originalQuestions));
    },

    // Reset everything and go back to the main menu
    resetAll() {
      this.isStarted = false;
      this.chosenOption = null;
      this.questions = [];
      this.originalQuestions = [];
    },

    // Update questions array
    updateQuestions(newQuestions) {
      this.questions = newQuestions;
    },

    // Update isStarted state
    updateIsStarted(newIsStarted) {
      this.isStarted = newIsStarted;
      if (!newIsStarted) {
        this.chosenOption = null;
        this.questions = [];
        this.originalQuestions = [];
      }
    },

    // Populate questions array
    populateQuestions(chosenOption) {
      if(chosenOption === null || chosenOption === undefined) return;

      console.log('Populating questions...');

      const chosenCategoryId = chosenOption.categoryId;
      const categoryOptionsOverride = chosenOption.optionsOverride;

      const category = this.categories
        .find(category => category.id === chosenCategoryId);

      if(category == null) return;

      const categoryData = require(`../assets/${category.type}/${category.file}.json`);

      if(category.type === "ipa-matching") {
        this.populateIpaMatchingQuestions(category, categoryData, categoryOptionsOverride);
      }

      if(category.type === "vowel-matching") {
        this.populateVowelMatchingQuestions(category, categoryData, categoryOptionsOverride);
      }

      if(category.type === "audio-matching") {
        this.populateAudioMatchingQuestions(category, categoryData, categoryOptionsOverride);
      }

      // Store the original questions
      this.originalQuestions = JSON.parse(JSON.stringify(this.questions));
    },

    populateIpaMatchingQuestions(category, categoryData, categoryOptionsOverride = null) {
      if (categoryData.pairs.length <= 0) return;
      console.log('Populating questions for type ipa-matching...');

      // Consider overriden options too
      var categoryDirection = (categoryOptionsOverride != null 
          && categoryOptionsOverride.direction != null)
            ? categoryOptionsOverride.direction
            : category.options.direction;

      var categoryMaxChoiceLimit = (categoryOptionsOverride != null 
          && categoryOptionsOverride.maxChoiceLimit != null)
            ? categoryOptionsOverride.maxChoiceLimit
            : category.options.maxChoiceLimit;

      if(categoryDirection === 'word-to-ipa' 
          || categoryDirection === 'bidirectional' ) {
        this.addWordToPronunciationQuestions(
          categoryData, categoryMaxChoiceLimit);
      }

      if(categoryDirection === 'ipa-to-word' 
          || categoryDirection === 'bidirectional' ) {
        this.addPronunciationToWordQuestions(
          categoryData, categoryMaxChoiceLimit);
      }

      // Shuffle questions to prevent same questions at the start
      this.questions = this.shuffle(this.questions);

      // Consider overriden options for limits too
      var categoryMaxQuestionLimit = (categoryOptionsOverride != null 
          && categoryOptionsOverride.maxQuestionLimit != null)
            ? categoryOptionsOverride.maxQuestionLimit
            : category.options.maxQuestionLimit;

      if (categoryMaxQuestionLimit && categoryMaxQuestionLimit > 0) {
        this.questions = this.questions.slice(0, categoryMaxQuestionLimit);
      }
    },

    populateAudioMatchingQuestions(category, categoryData, categoryOptionsOverride = null) {
      if (categoryData.pairs.length <= 0) return;
      console.log('Populating questions for type audio-matching...');

      var categoryMaxChoiceLimit = (categoryOptionsOverride != null 
          && categoryOptionsOverride.maxChoiceLimit != null)
            ? categoryOptionsOverride.maxChoiceLimit
            : category.options.maxChoiceLimit;

      this.addAudioToWordQuestions(categoryData, categoryMaxChoiceLimit);

      // Shuffle questions to prevent same questions at the start
      this.questions = this.shuffle(this.questions);

      // Limit the number of questions, if option is overriden
      var categoryMaxQuestionLimit = (categoryOptionsOverride != null 
          && categoryOptionsOverride.maxQuestionLimit != null)
            ? categoryOptionsOverride.maxQuestionLimit
            : category.options.maxQuestionLimit;

      if (categoryMaxQuestionLimit && categoryMaxQuestionLimit > 0) {
        this.questions = this.questions.slice(0, categoryMaxQuestionLimit);
      }
    },

    populateVowelMatchingQuestions(category, categoryData, categoryOptionsOverride = null) {
      if (categoryData.pairs.length <= 0) return;
      console.log('Populating questions for type vowel-matching...');

      // don't add audio for now
      this.buildVowelGuessingQuestions(categoryData);

      // Shuffle questions to prevent same questions at the start
      this.questions = this.shuffle(this.questions);

      // Limit the number of questions, if option is overriden
      var categoryMaxQuestionLimit = (categoryOptionsOverride != null 
          && categoryOptionsOverride.maxQuestionLimit != null)
            ? categoryOptionsOverride.maxQuestionLimit
            : category.options.maxQuestionLimit;

      if (categoryMaxQuestionLimit && categoryMaxQuestionLimit > 0) {
        this.questions = this.questions.slice(0, categoryMaxQuestionLimit);
      }
    },

    addWordToPronunciationQuestions(categoryData, maxChoiceLimit) {
      categoryData.pairs.forEach((pair) => {
        const question = pair.word;
        const correctAnswer = pair.pronunciation;
        const availableAnswers = categoryData.pairs
          .filter(p => p.word !== question).map(pair => pair.pronunciation);
        const newQuestion = this.addIpaMatchingPair(
          question, correctAnswer, availableAnswers, maxChoiceLimit);
        this.questions.push(newQuestion);
      });
    },

    buildVowelGuessingQuestions(categoryData) {
      categoryData.pairs.forEach((pair) => {
        const foundVowelObject = this.findAndReplaceVowel(pair.pronunciation);
        if(foundVowelObject !== null) {

          const correctAnswer = foundVowelObject.firstVowel;
          const question = `${pair.word} ${foundVowelObject.modifiedPronunciation}`;

          const newQuestion = this.addVowelMatchingPair(question, correctAnswer);
          this.questions.push(newQuestion);
        }
      });
    },

    findAndReplaceVowel(pronunciation) {
      // Find the index of the stress mark
      const stressIndex = pronunciation.indexOf("'");

      // Start searching from after the stress mark, if it exists
      const searchStart = stressIndex !== -1 ? stressIndex + 1 : 0;

      // Search for the first vowel in the pronunciation
      for (const vowel of this.vowels) {
        const index = pronunciation.indexOf(vowel, searchStart);
        if (index !== -1) {

          return {
            firstVowel: vowel,
            modifiedPronunciation:  pronunciation.slice(0, index) + '•' + pronunciation.slice(index + vowel.length)
          };
        }
      }

      return null;
    },

    addPronunciationToWordQuestions(categoryData, maxChoiceLimit) {
      categoryData.pairs.forEach((pair) => {
        const question = pair.pronunciation;
        const correctAnswer = pair.word;
        const availableAnswers = categoryData.pairs
          .filter(p => p.pronunciation !== question).map(pair => pair.word);


        const newQuestion = this.addIpaMatchingPair(
          question, correctAnswer, availableAnswers, maxChoiceLimit);
        this.questions.push(newQuestion);
      });
    },

    addAudioToWordQuestions(categoryData, maxChoiceLimit) {
      categoryData.pairs.forEach((pair) => {
        const question = pair.pronunciation;
        const correctAnswer = pair.word;
        const availableAnswers = categoryData.pairs
          .filter(p => p.pronunciation !== question).map(pair => pair.word);
        const newQuestion = this.addIpaMatchingPair(
          question, correctAnswer, availableAnswers, maxChoiceLimit);
        
        // Add audio
        newQuestion.isAudio = true;
        newQuestion.audioFile = pair.audio;
        newQuestion.availableAnswersAudio = categoryData.pairs.reduce((acc, pair) => {
          acc[pair.word] = pair.audio;
          return acc;
        }, {});
        newQuestion.availableAnswersPronunciation = categoryData.pairs.reduce((acc, pair) => {
          acc[pair.word] = pair.pronunciation;
          return acc;
        }, {});
        
        this.questions.push(newQuestion);
      });
    },

    addIpaMatchingPair(question, correctAnswer, availableAnswers, maxChoiceLimit) {
      const newQuestion = {};
      const answers = [];

      // remove correct answer from available answers to get incorrect answers
      let incorrectAnswers = availableAnswers.filter(answer => answer !== correctAnswer);

      // Shuffle incorrect answers to add entropy
      this.shuffle(incorrectAnswers);

      // Limit the number of choices, if option is specified
      if (maxChoiceLimit && maxChoiceLimit > 0) {
        incorrectAnswers = incorrectAnswers.slice(0, maxChoiceLimit - 1);
      }

      [
        ...incorrectAnswers, // Incorrect answers
        correctAnswer,       // Correct answer
      ].forEach((answer) => {
        let answerObject = { text: '', correct: false };

        [answerObject.text, answerObject.correct] = [
          answer,
          answer === correctAnswer,
        ];

        answers.push(answerObject);
      });

      newQuestion.text = question;
      newQuestion.answers = this.shuffle(answers);

      return newQuestion;
    },

    addVowelMatchingPair(question, correctAnswer) {
      const newQuestion = {};
      const answers = [];

      // remove correct answer from available answers to get incorrect answers
      let incorrectAnswers = this.vowels.filter(answer => answer !== correctAnswer);

      // Shuffle incorrect answers to add entropy
      this.shuffle(incorrectAnswers);
      [
        ...incorrectAnswers, // Incorrect answers
        correctAnswer,       // Correct answer
      ].forEach((answer) => {
        let answerObject = { text: '', correct: false };

        [answerObject.text, answerObject.correct] = [
          answer,
          answer === correctAnswer,
        ];

        answers.push(answerObject);
      });

      newQuestion.text = question;
      newQuestion.answers = this.sortAnswers(answers);

      return newQuestion;
    },

    sortAnswers(answers) {
      return answers.slice().sort((a, b) => a.text.localeCompare(b.text));
    },

    // Utility Durstenfeld array shuffle
    shuffle(arr) {
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}
.quiz-container {
  display: flex;
  position: relative;
  justify-content: center;
  max-width: 50rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media screen and (min-width: 768px) {
    padding: 3rem 1rem;
  }
  @media screen and (min-width: 1200px) {
    max-width: 70rem;
  }
}
.setup-container {
  h1 {
    margin-top: 0;
  }
  h2 {
    font-size: 1.5rem;
    margin: 3rem auto 2rem;
    &::after {
      content: '';
      display: block;
      border-bottom: 5px solid #fff;
      width: 100px;
      margin: 1rem auto 0;
    }
  }
}
.categories-container,
.setup-option {
  flex: 1 1 auto;
  min-width: 100px;
  margin: 0.5rem;
  padding: 0.875rem;
  font-size: 1.125rem;
  color: #313030;
  background-color: $option-bg;
  border-radius: 1px;
  cursor: pointer;
  transition: all 0.1s;
  &:hover {
    color: #fff;
    background-color: $option-hover;
  }
  &.is-selected {
    background-color: $option-bg--active;
    color: #fff;
  }
}
.button--start {
  border-radius: 8px;
  margin-top: 3rem;
  color: #fff;
  background-color: $btn-bg--active;
  &:hover {
    background-color: $btn-hover;
  }
}
.quiz {
  display: flex;
  flex-flow: row wrap;
  max-width: 40rem;
  width: 100%;
  & > * {
    flex: 1 1 100%;
  }
}

.answers-container {
  margin-top: 1rem;
  @media screen and (min-width: 768px) {
    margin-top: 3rem;
  }
}

.answer {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  color: #313030;
  background-color: #fff;
  border-radius: 8px;
  &--option {
    max-width: 100%;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.1s;
    &:hover {
      color: #fff;
      background-color: $option-hover;
    }
    &.is-selected {
      color: #fff;
      background-color: $option-bg--active;
    }
    & + * {
      margin-top: 0.75rem;
    }
  }
  &--correct,
  &--incorrect {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    padding: 1.25rem 1.5rem;
    color: #fff;
    @media screen and (min-width: 768px) {
      max-width: 50%;
    }
  }
  &--correct {
    background-color: #38d878;
  }
  &--incorrect {
    background-color: #e74144;
  }
}

.quiz-answer {
  margin: 4rem 2rem 6rem;
  .question {
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
    &::after {
      content: '';
      display: block;
      border-bottom: 5px solid #fff;
      width: 100px;
      margin: 2rem auto 0;
    }
  }
}
</style>

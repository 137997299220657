<template>
<div>
    <h2>{{ sectionName }}</h2>
    <div class="category-buttons">
        <div
            class="setup-option"
            v-for="category in filterCategories(type, direction)"
            :class="{ 'is-selected': chosenOption !== null && chosenOption.categoryId === category.id && sectionName === chosenOption.sectionName }"
            :key="category.id"
            @click="setOption(category.id, sectionName, direction, maxQuestionLimit, maxChoiceLimit)"
        >{{ category.name }}</div>
    </div>
</div>
</template>
<script>
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
  props: {
    source: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    direction: {
      type: String,
      default: null
    },
    chosenOption: {
      type: Object,
      required: false
    },
    maxQuestionLimit: {
      type: Number,
      default: null
    },
    maxChoiceLimit: {
      type: Number,
      default: null
    },
    sectionName: {
      type: String,
      default: "Section"
    }
  },
  setup(props, { emit }) {
    const { source } = toRefs(props);

    const setOption = (
      categoryId, 
      sectionName = null,
      direction = null,
      maxQuestionLimit = null,
      maxChoiceLimit = null
    ) => {
      let categoryOptionsOverride = null;

      // Override options
      if (direction !== null || maxQuestionLimit !== null || maxChoiceLimit !== null) {
        categoryOptionsOverride = {
          direction,
          maxQuestionLimit,
          maxChoiceLimit
        };
      }

      const option = { 
        categoryId: categoryId,
        optionsOverride: categoryOptionsOverride,
        sectionName: sectionName,
      };

      emit('category-selected', option);
    };

    const filterCategories = (type, direction) => {
      return source.value.filter(c => c.type === type && (direction === null || c.options.direction === "bidirectional" || c.options.direction === direction));
    };

    return {
      setOption,
      filterCategories
    };
  }
});
</script>

<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}

.setup-container {
  h1 {
    margin-top: 0;
  }
  h2 {
    font-size: 1.5rem;
    margin: 3rem auto 2rem;
    &::after {
      content: '';
      display: block;
      border-bottom: 5px solid #fff;
      width: 100px;
      margin: 1rem auto 0;
    }
  }
}
.setup-option {
  flex: 0 0 auto; /* Allow buttons to shrink if needed */
  min-width: 100px;
  margin: 0.5rem;
  padding: 0.875rem;
  font-size: 1.125rem;
  color: #313030;
  background-color: $option-bg;
  border-radius: 1px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.1s;
  &:hover {
    color: #fff;
    background-color: $option-hover;
  }
  &.is-selected {
    background-color: $option-bg--active;
    color: #fff;
  }
}

.category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
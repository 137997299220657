// import { createApp } from 'vue';

// import appInsights from './appInsights'; // Import your App Insights configuration

// const app = createApp(App);

// // Mount the app
// app.mount('#app');

// // Track the initial page view
// app.config.globalProperties.$appInsights = appInsights;
// appInsights.trackPageView();

import App from './App.vue';

import { createApp } from "vue";
import { AppInsightsPlugin } from "vue3-application-insights";

const aiOptions = {
  appName: "US-Vowels",
  connectionString: "InstrumentationKey=e9fb0f28-929a-4c29-b2c6-58301556bbd4;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=7607bfa3-2f37-415c-944c-723840213591",
  trackAppErrors: true,
  trackInitialPageView: true
};

createApp(App).use(AppInsightsPlugin, aiOptions).mount("#app");
<template>
  <span v-if="isAudio">
    <br />
    <button @click="toggleAudio" class="play-stop-button">
      <span v-if="!isAudioPlaying">&#9654;</span> <!-- Play icon -->
      <span v-else>&#9724;</span> <!-- Stop icon -->
    </button>

    <audio ref="audioPlayer" @ended="audioEnded">
      <source :src="audioFile" type="audio/mp3">
      Your browser does not support the audio element.
    </audio>
  </span>
</template>
  
<script>
import { AUDIOMATCHING_FILES_DIRECTORY } from '@/utils/constants';
import { defineComponent, ref, computed, onMounted } from 'vue';

export default defineComponent({
  props: {
    autoplay: {
      type: Boolean,
      default: false
    },
    audioInfo: {
      type: Object,
      required: true,
      default: () => ({
        isAudio: false,
        audioFile: ''
      })
    }
  },
  setup(props) {
    const isAudioPlaying = ref(false);
    const audioPlayer = ref(null);

    const isAudio = computed(() => props.audioInfo.isAudio);
    const audioFile = computed(() => `${AUDIOMATCHING_FILES_DIRECTORY}/${props.audioInfo.audioFile}`);

    const toggleAudio = () => {
      if (isAudioPlaying.value) {
        audioPlayer.value.pause();
      } else {
        audioPlayer.value.play();
      }
      isAudioPlaying.value = !isAudioPlaying.value;
    };

    const audioEnded = () => {
      isAudioPlaying.value = false;
    };

    onMounted(() => {
      if (props.autoplay) {
        toggleAudio();
      }
    });

    return {
      isAudioPlaying,
      audioPlayer,
      isAudio,
      audioFile,
      toggleAudio,
      audioEnded
    };
}});
</script>
  
<style scoped>
.play-stop-button {
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  border-radius: 50%; /* Keep the button round */
  width: 50px; /* Fixed width */
  height: 50px; /* Fixed height */
  font-size: 24px; /* Adjusted font size */
  line-height: 50px; /* Adjust to match the button's height for vertical alignment */
  text-align: center; /* Center the symbol horizontally */
  display: inline-flex; /* Use flex to easily center content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.play-stop-button:hover {
  border: 2px solid #fff;
}
</style>
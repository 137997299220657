<template>
  <footer>
    <nav class="nav-container" role="navigation" aria-label="Navigation buttons">
      <button v-if="currentQuestionIndex === 0"
        class="button"
        type="button"
        title="Return to the Main Page"
        @click="resetAll"
      >Quit</button>
      <button v-else
        class="button"
        type="button"
        @click="prevQuestion"
        :disabled="currentQuestionIndex < 1"
      >Back</button>
      <button
        class="button button--next"
        type="button"
        @click="nextOrSubmit"
        :disabled="chosenAnswers[currentQuestionIndex] == null || currentQuestionIndex >= questions.length"
      >{{ currentQuestionIndex === questions.length - 1 ? 'Submit' : 'Next' }}</button>
      <ul class="nav-steps">
        <li
          class="nav-step"
          v-for="(question, index) in questions"
          :key="index"
          @click="selectQuestion(index)"
        >
          <button
            class="button"
            type="button"
            :disabled="chosenAnswers.length < index"
            :class="{'is-active': currentQuestionIndex === index}"
          >{{ index + 1 }}</button>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterNav',
  props: {
    questions: {
      type: Array,
      required: true
    },
    chosenAnswers: {
      type: Array,
      required: true
    },
    currentQuestionIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    // Decrement currentQuestionIndex
    const prevQuestion = () => {
      if (props.questions.length > 0 && props.currentQuestionIndex > 0) {
        emit('update-question-index', props.currentQuestionIndex - 1);
      }
    };

    // Increment currentQuestionIndex
    const nextOrSubmit = () => {
      if (props.currentQuestionIndex < props.questions.length ) {
        emit('update-question-index', props.currentQuestionIndex + 1);
      }
    };
    
    // Update currentQuestionIndex to given index
    const selectQuestion = (index) => {
      if (props.chosenAnswers.length >= index) {
        emit('update-question-index', index);
      }
    };

    // Reset everything for new setup
    const resetAll = () => {
      emit('reset-all');
    };

    return {
      prevQuestion,
      nextOrSubmit,
      selectQuestion,
      resetAll
    };
  }
});
</script>

<style lang="scss" scoped>
.button {
  border-radius: 8px;
  &--next {
    color: #fff;
    background-color: $btn-bg--active;
    &:hover {
      background-color: $btn-hover;
    }
  }
}
.nav-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 3rem;
  .nav-steps {
    flex: 1 0 100%;
    margin-top: 2rem;
    .nav-step {
      margin: 10px 6px;
    }
    .button {
      width: 3rem;
      height: 3rem;
      min-width: auto;
      padding: 0;  
      border-radius: 8px;
    }
    .is-active {
      color: #fff;
      background-color: $btn-bg--active;
    }
  }
}
</style>
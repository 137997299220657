<template>
  <div class="quiz-completed">
    <p class="score">{{ calcScore }} / {{ questions.length }}</p>
    <h2 class="completion-message">{{ completionMessage }}</h2>
    <div class="quiz-answers">
      <div
        class="quiz-answer"
        v-for="(question, index) in questions"
        :class="{ 'is-selected': chosenAnswers[currentQuestionIndex] === index}"
        :key="index"
      >
        <h3 class="question" v-if="question && question.text">{{ question.text }}</h3>
        
        <p class="answer answer--correct">
          Correct answer<br/>
          <span class="highlight-word">{{ correctAnswerText(question) }}</span>
          <span class="pronunciation">{{ getChosenAnswerPronunciation(question, question.answers.find(a => a.correct == true).text) }}</span>
          <AudioPlayer :audioInfo="question" />
        </p>
        <p v-if="!isAnswerCorrect(question, chosenAnswers[index])" class="answer answer--incorrect">
          Your answer<br/>
          <span class="highlight-word">{{ chosenAnswerText(question, chosenAnswers[index]) }}</span>
          <span class="pronunciation">{{ getChosenAnswerPronunciation(question, question.answers[chosenAnswers[index]].text) }}</span>
          <AudioPlayer v-if="isAnswerAudioAvailable(question, chosenAnswers[index])" :audioInfo="chosenAnswerAudio(question, chosenAnswers[index])"/>
        </p>
      </div>
    </div>
    <div class="restart-buttons">
      <button class="button" type="button" title="Try again this activity with the same questions" @click="tryAgain">Try again</button>
      <button class="button" type="button" title="Restart this activity with different questions" @click="resetQuestions">Restart</button>
      <button class="button" type="button" title="Go to the main page to pick a new quiz" @click="$emit('reset-all')">Pick New Quiz</button>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import AudioPlayer from './AudioPlayer.vue';
import { useAppInsights } from "vue3-application-insights";


export default {
  name: 'QuizCompleted',
  components: {
    AudioPlayer,
  },
  props: {
    questions: Array,
    chosenAnswers: Array,
    currentQuestionIndex: Number,
  },
  setup(props, { emit }) {
    // Computed properties
    const calcScore = computed(() => {
      let total = 0;
      props.chosenAnswers.forEach((answerIndex, index) => {
        const question = props.questions[index];
        if (question && question.answers[answerIndex] && question.answers[answerIndex].correct) {
          total += 1;
        }
      });
      return total;
    });

    const completionMessage = computed(() => {
      const score = calcScore.value;
      const totalQuestions = props.questions.length;

      if (score >= totalQuestions * 0.8) {
        return 'Great work!';
      } else if (score >= totalQuestions / 2) {
        return 'Good work!';
      } else {
        return 'Better luck next time';
      }
    });

    // Methods
    const tryAgain = () => {
      emit('try-again');
    };

    const resetQuestions = () => {
      emit('update-questions', []);
      emit('start-quiz');
    };

    const isAnswerCorrect = (question, answerIndex) => {
      return question.answers[answerIndex] && question.answers[answerIndex].correct;
    };

    const correctAnswerText = (question) => {
      const correctAnswer = question.answers.find(answer => answer.correct);
      return correctAnswer ? correctAnswer.text : '';
    };

    const chosenAnswerText = (question, answerIndex) => {
      return question.answers[answerIndex] ? question.answers[answerIndex].text : '';
    };

    const getChosenAnswerPronunciation = (question, answerIndex) => {
      const chosenAnswer = chosenAnswerText(question, answerIndex);
      if (question.availableAnswersPronunciation && chosenAnswer in question.availableAnswersPronunciation) {
        return question.availableAnswersPronunciation[chosenAnswer];
      }
      return '';
    };

    const chosenAnswerAudio = (question, answerIndex) => {
      const chosenAnswer = chosenAnswerText(question, answerIndex);
      if (question.availableAnswersAudio && chosenAnswer in question.availableAnswersAudio) {
        return {
          isAudio: true,
          audioFile: question.availableAnswersAudio[chosenAnswer]
        };
      }
      return {
        isAudio: false,
        audioFile: ''
      };
    };

    const isAnswerAudioAvailable = (question, answerIndex) => {
      const chosenAnswer = chosenAnswerText(question, answerIndex);
      return question.availableAnswersAudio && chosenAnswer in question.availableAnswersAudio;
    };

    // Track event when component (page) is mounted
    onMounted(() => {
      const appInsights = useAppInsights();

      console.log('TRACKING');

      var customEvent = {
        name: 'QuizCompletion',
        properties: {
          score: calcScore.value,
          totalQuestions: props.questions.length,
          completionMessage: completionMessage.value,
        },
      };

      console.log('TRACKING: ' + JSON.stringify(customEvent));

      appInsights.trackEvent(customEvent);
    });

    return {
      calcScore,
      completionMessage,
      tryAgain,
      resetQuestions,
      isAnswerCorrect,
      correctAnswerText,
      chosenAnswerText,
      getChosenAnswerPronunciation,
      chosenAnswerAudio,
      isAnswerAudioAvailable,
    };
  },
};
</script>

<style lang="scss" scoped>
.completion-message {
  font-size: 2.5rem;
}
.score {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  font-size: 3rem;
  font-weight: 600;
  border: 0.375rem solid;
  border-radius: 50%;
}
.quiz-answers {
  margin-top: 3rem;
  border-top: 2px solid;
}
.quiz-answer {
  margin: 4rem 2rem 6rem;
  .question {
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
    &::after {
      content: '';
      display: block;
      border-bottom: 5px solid #fff;
      width: 100px;
      margin: 2rem auto 0;
    }
  }
}
.pronunciation {
  font-size: 1.125rem;
  margin-left: 5px;
}

.highlight-word {
  font-weight: 600;
  font-size: 1.5rem;
}  
  
.restart-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  .button {
    min-width: 290px;
    margin: 1rem 0.5rem 0;
    border-radius: 8px;
    transition: all 0.1s;

    &:hover {
      color: #fff;
      background-color: $option-hover;
      transition: background-color 0.3s ease, color 0.3s ease;
    }
  }
}

.setup-container {
  h1 {
    margin-top: 0;
  }
  h2 {
    font-size: 1.5rem;
    margin: 3rem auto 2rem;
    &::after {
      content: '';
      display: block;
      border-bottom: 5px solid #fff;
      width: 100px;
      margin: 1rem auto 0;
    }
  }
}

.answer {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  color: #313030;
  background-color: #fff;
  border-radius: 8px;
  &--option {
    max-width: 100%;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.1s;
    &:hover {
      color: #fff;
    }
    &.is-selected {
      color: #fff;
    }
    & + * {
      margin-top: 0.75rem;
    }
  }
  &--correct,
  &--incorrect {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    padding: 1.25rem 1.5rem;
    color: #fff;
    @media screen and (min-width: 768px) {
      max-width: 50%;
    }
  }
  &--correct {
    background-color: #38d878;
  }
  &--incorrect {
    background-color: #e74144;
  }
}

</style>
<template>
  <div class="quiz-content">
    <Loading v-if="questions.length == 0" text="Loading..." />

    <!-- Quiz content -->
    <div v-else-if="currentQuestionIndex < questions.length" class="quiz" :key="currentQuestionIndex">
      <div class="player" v-if="questions[currentQuestionIndex].isAudio">
        <AudioPlayer autoplay :audioInfo="questions[currentQuestionIndex]" />
      </div>

      <h2 v-else>{{ decodeHtml(questions[currentQuestionIndex].text) }}</h2>
      <div :class="{ 'answers-container': hasManyAnswers }">
        <div
          :class="[
            { 'is-selected': chosenAnswers[currentQuestionIndex] == index },
            { 'answer answer--grid-option': hasManyAnswers },
            { 'answer answer--option': !hasManyAnswers },
            { 'answer--correct': feedback[currentQuestionIndex] === 'correct' && chosenAnswers[currentQuestionIndex] === index },
            { 'answer--incorrect': feedback[currentQuestionIndex] === 'incorrect' && chosenAnswers[currentQuestionIndex] === index },
            { 'answer--correct-reveal': feedback[currentQuestionIndex] === 'incorrect' && correctAnswerIndex[currentQuestionIndex] === index }
          ]"
          v-for="(answer, index) in questions[currentQuestionIndex].answers"
          @click="selectAnswer(index)"
          :key="index"
        >
          {{ decodeHtml(answer.text) }}
          <span v-if="feedback[currentQuestionIndex] === 'correct' && chosenAnswers[currentQuestionIndex] === index" class="feedback-icon">✓</span>
          <span v-if="feedback[currentQuestionIndex] === 'incorrect' && chosenAnswers[currentQuestionIndex] === index" class="feedback-icon">✗</span>
          <span v-if="feedback[currentQuestionIndex] === 'incorrect' && correctAnswerIndex[currentQuestionIndex] === index" class="feedback-icon">✓</span>
        </div>
      </div>
      <FooterNav
        :questions="questions"
        :chosenAnswers="chosenAnswers"
        :currentQuestionIndex="currentQuestionIndex"
        @update-question-index="updateQuestionIndex"
        @update-chosen-answers="chosenAnswers = $event"
        @update-chosen-option="updateChosenOption"
        @update-is-started="updateIsStarted"
        @reset-all="resetAll"
      />
    </div>

    <!-- Quiz completed -->
    <div v-else class="quiz-completed">
      <QuizCompleted
        :questions="questions"
        :chosenAnswers="chosenAnswers"
        :currentQuestionIndex="currentQuestionIndex"
        @update-question-index="updateQuestionIndex"
        @update-questions="updateQuestions"
        @update-chosen-answers="chosenAnswers = $event"
        @update-chosen-option="updateChosenOption"
        @update-is-started="updateIsStarted"
        @start-quiz="startQuiz"
        @try-again="tryAgain"
        @reset-all="resetAll"
      />
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, computed, watch } from 'vue';
import AudioPlayer from './AudioPlayer.vue';
import Loading from './LoadingMain.vue';
import FooterNav from './FooterNav.vue';
import QuizCompleted from './QuizCompleted.vue';

export default defineComponent({
  name: 'QuizContent',
  components: {
    AudioPlayer,
    FooterNav,
    QuizCompleted,
    Loading
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    quizResetTrigger: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const categories = ref([]);
    const chosenAnswers = ref([]);
    const currentQuestionIndex = ref(0);
    const feedback = ref([]);
    const correctAnswerIndex = ref([]);

    const selectAnswer = (index) => {
      if (feedback.value[currentQuestionIndex.value] === undefined) {
        chosenAnswers.value[currentQuestionIndex.value] = index;
        const currentQuestion = props.questions[currentQuestionIndex.value];
        const isCorrect = currentQuestion.answers[index].correct;
        feedback.value[currentQuestionIndex.value] = isCorrect ? 'correct' : 'incorrect';
        
        if (!isCorrect) {
          // Find the correct answer
          const correctIndex = currentQuestion.answers.findIndex(answer => answer.correct);
          correctAnswerIndex.value[currentQuestionIndex.value] = correctIndex;
        }
      }
    };

    const updateQuestions = (questions) => {
      emit('update-questions', questions);
    };

    const updateChosenOption = (chosenOption) => {
      emit('update-chosen-option', chosenOption);
    };

    const updateIsStarted = (isStarted) => {
      emit('update-is-started', isStarted);
    };

    const updateQuestionIndex = (index) => {
      currentQuestionIndex.value = index;
    };

    const resetQuizState = () => {
      chosenAnswers.value = [];
      feedback.value = [];
      correctAnswerIndex.value = [];
      currentQuestionIndex.value = 0;
    };

    const startQuiz = () => {
      resetQuizState();
      emit('start-quiz');
    };

    const tryAgain = () => {
      resetQuizState();
      emit('reset-quiz');
    };

    const resetAll = () => {
      emit('reset-all');
    };

    const hasManyAnswers = computed(() => {
      return props.questions[currentQuestionIndex.value].answers.length > 10;
    });

    const decodeHtml = (html) => {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = html;
      return textArea.value;
    };

    // Watch for changes in the quizResetTrigger prop
    watch(() => props.quizResetTrigger, () => {
      resetQuizState();
    });

    // Watch for changes in the questions prop
    watch(() => props.questions, () => {
      resetQuizState();
    });

    return {
      categories,
      chosenAnswers,
      currentQuestionIndex,
      feedback,
      correctAnswerIndex,
      selectAnswer,
      updateQuestions,
      updateChosenOption,
      updateIsStarted,
      updateQuestionIndex,
      startQuiz,
      tryAgain,
      resetAll,
      hasManyAnswers,
      decodeHtml
    };
  }
});
</script>

<style lang="scss" scoped>
.player {
  margin-bottom: 20px
}
.completion-message {
  font-size: 2.5rem;
}
.score {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  font-size: 3rem;
  font-weight: 600;
  border: 0.375rem solid;
  border-radius: 50%;
}
.quiz-answers {
  margin-top: 3rem;
  border-top: 2px solid;
}
.quiz-answer {
  margin: 4rem 2rem 6rem;
  .question {
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
    &::after {
      content: '';
      display: block;
      border-bottom: 5px solid #fff;
      width: 100px;
      margin: 2rem auto 0;
    }
  }
}
.pronunciation {
  font-size: 1.125rem;
  margin-left: 5px;
}

.highlight-word {
  font-weight: 600;
  font-size: 1.5rem;
}

.setup-container {
  h1 {
    margin-top: 0;
  }
  h2 {
    font-size: 1.5rem;
    margin: 3rem auto 2rem;
    &::after {
      content: '';
      display: block;
      border-bottom: 5px solid #fff;
      width: 100px;
      margin: 1rem auto 0;
    }
  }
}

.answers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: grid;
  grid-column-gap: 0.01rem; /* Smaller gap between columns */
  grid-row-gap: 0.75rem; /* Larger gap between rows */
  grid-template-columns: repeat(4, 1fr); /* 4 columns of equal width */
  grid-template-rows: repeat(4, auto); /* 4 rows, adjust the height as needed */
  justify-content: center;
}

.answer {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  color: #313030;
  background-color: #fff;
  border-radius: 8px;
  position: relative;

  &--option {
    max-width: 100%;
    padding: 0.5rem 1rem; /* Adjust padding as needed */
    cursor: pointer;
    transition: all 0.1s;
    text-align: center; /* Center text within button */

    &:hover {
      color: #fff;
      background-color: $option-hover;
      transition: background-color 0.3s ease, color 0.3s ease;
    }

    &.is-selected {
      color: #fff;
      background-color: $option-bg--active;
    }

    & + * {
      margin-top: 0.75rem;
    }
  }

  &--grid-option {
    padding: 0.8rem 1rem; /* Adjust padding as needed */
    cursor: pointer;
    width: 2rem;
    transition: all 0.1s;
    text-align: center; /* Center text within button */

    &:hover {
      color: #fff;
      background-color: $option-hover;
      transition: background-color 0.3s ease, color 0.3s ease;
    }

    &.is-selected {
      color: #fff;
      background-color: $option-bg--active;
    }
  }

  &--correct {
    background-color: #38d878 !important;
    color: #fff !important;
  }

  &--incorrect {
    background-color: #e74144 !important;
    color: #fff !important;
  }

  &--correct-reveal {
    background-color: #38d878 !important;
    color: #fff !important;
  }
}

.feedback-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
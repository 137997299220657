<template>
    <div class="header-logo">
      <h1>
        <span class="u">U</span>
        <span class="s">S</span>
        vowels
      </h1>
    </div>
  </template>
  
  <style scoped>
  .header-logo {
    display: flex;
    justify-content: center;
  }

  .header-logo h1 {
    font-size: 4.5em;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  
  .header-logo .u, .header-logo .s {
    display: inline-block;
    padding: 0 0px;
    margin-right: 5px;
  }
  
  .header-logo .u {
    /* background: url('path-to-stars-image.jpg') no-repeat center; */
    background-size: cover;
    color: blue;
  }
  
  .header-logo .s {
    background: linear-gradient(
        to bottom,
        red 12.5%, white 12.5%, white 25%, red 25%, red 37.5%, white 37.5%, white 50%, red 50%, red 62.5%, white 62.5%, white 75%, red 75%, red 87.5%, white 87.5%, white 100%
    );
    -webkit-background-clip: text;
    color: transparent;
  }

  
  .header-logo h1 span {
    font-family: Arial, sans-serif;
  }
  </style>
  